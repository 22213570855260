import React from "react";
import { Stack, TableCell, TableRow } from "@mui/material";
import { PrimaryText } from "components/Common/PrimaryText";
import { COLORS } from "constants/Colors";
import { TouchableOpacity } from "react-native-web";
import { ProfilePic } from "components/Pics/ProfilePic";
import moment from "moment";
import { dollarFormatter } from "utils/helpers";

const colors = {
	Pending: COLORS.primary.error,
	Processing: COLORS.primary.accent_500,
	Completed: COLORS.primary.success_500
};

export default function WalletActivityCard(props) {
	const { item } = props;

	const { date, recurring, amount, type, bank = {}, nonprofit, employee = false, from = {}, to = {}, status, balance = 0 } = item;

	let description;
	let title;
	let color = COLORS.primary.green;
	if (type === "donation") {
		// title = nonprofit?.name;
		color = COLORS.primary.error;
		description = "Donation to " + nonprofit?.name;
	} else if (employee && from?.name) {
		// title = from?.name;
		description = "Transfer from " + from?.name;
	} else if (employee && to?.names?.length) {
		// title = to?.name;
		description = "Transfer to " + to?.names.join(", ");
		color = COLORS.primary.error;
	} else {
		// title = "Bank Transfer";
		description = "Bank Transfer from acct #" + bank?.last4;
	}

	return (
		<TableRow>
			<TableCell>
				<PrimaryText
					fontSize={16}
					fontWeight={500}
				>
					{moment(date.toDate()).format("MM/DD/YYYY")}
					{/* {date} */}
				</PrimaryText>
			</TableCell>

			<TableCell>
				<PrimaryText
					fontSize={16}
					fontWeight={500}
				>
					{description}
				</PrimaryText>
			</TableCell>

			<TableCell>
				<PrimaryText
					fontSize={16}
					fontWeight={500}
				>
					{/* {moment(latestDonation.date.toDate()).format("MM/DD/YYYY")} */}
					{type}
				</PrimaryText>
			</TableCell>

			<TableCell>
				<PrimaryText
					fontSize={16}
					fontWeight={500}
					color={color}
				>
					{dollarFormatter.format(amount)}
				</PrimaryText>
			</TableCell>

			<TableCell>
				<PrimaryText
					fontSize={16}
					fontWeight={500}
				>
					{dollarFormatter.format(balance)}
				</PrimaryText>
			</TableCell>
		</TableRow>
	);
}
