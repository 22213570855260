import {
	getOrgPromise,
	updateOrganization
} from "services/organizations-service";
import { firestore, functions, auth } from "utils/firebase";
import firebase from "firebase/compat/app";

export const getRecentBusinessDonations = (selectedOrg, setHistory, users) => {
	return firestore
		.collection("donations")
		.where("corporateMatch", "==", selectedOrg?.id) // selectedOrg?.id)
		.orderBy("date", "desc")
		.onSnapshot(
			(querySnapshot) => {
				let history = [];
				querySnapshot.forEach((doc) => {
					history.push({
						corporateStatus: 0,
						...doc.data(),
						id: doc.id
					});
				});

				if (history.length < 1) return setHistory([]);

				let promises = history.map((item, index) =>
					getOrgPromise(item, item.orgId)
				);
				Promise.all(promises).then((donations) => {
					donations = donations.map((item, index) => {
						return {
							...item,
							user: users.find((x) => x.id === item.userId)
						};
					});

					setHistory(donations);
				});
			},
			(e) => {
				console.log("getRecentDonations error", e);
			}
		);
};

export const getOneTimeBusinessDonations = (selectedOrg, setHistory, users) => {
	return firestore
		.collection("donations")
		.where("companyId", "==", selectedOrg?.id) // selectedOrg?.id)
		.onSnapshot(
			(querySnapshot) => {
				let history = [];
				querySnapshot.forEach((doc) => {
					history.push({
						corporateStatus: 0,
						...doc.data(),
						id: doc.id
					});
				});

				if (history.length < 1) return setHistory([]);

				let promises = history.map((item, index) =>
					getOrgPromise(item, item.orgId)
				);
				Promise.all(promises).then((donations) => {
					donations = donations.map((item, index) => {
						return {
							...item,
							user: { firstName: selectedOrg.name, lastName: "" },
							corporateStatus: 1
						};
					});

					setHistory(donations);
				});
			},
			(e) => {
				console.log("getRecentDonations error", e);
			}
		);
};

export const getMatchedDonations = (selectedOrg, setHistory, users) => {
	return firestore
		.collection("donations_corporate_match")
		.where("corporateMatch", "==", selectedOrg?.id) // selectedOrg?.id)
		.orderBy("date", "desc")
		.onSnapshot(
			(querySnapshot) => {
				let history = [];
				querySnapshot.forEach((doc) => {
					history.push({
						...doc.data(),
						id: doc.id
					});
				});

				setHistory(history);
			},
			(e) => {
				console.log("getRecentDonations error", e);
			}
		);
};

export const approveBusinessDonation = (donation, business) => {
	console.log("approveBusinessDonation", donation.id);
	//handle Stripe transaction here
	createPayment(business, donation);
	//update donation
	return updateDonation(donation.id, { corporateStatus: 1 });
};

export const denyBusinessDonation = (donationId) => {
	return updateDonation(donationId, { corporateStatus: 2 });
};

function updateDonation(donationId, data) {
	return firestore.collection("donations").doc(donationId).update(data);
}

const createPayment = async (business, donation) => {
	const amount = Math.round(
		(business?.matchPercentage * donation?.amount) / 100
	);
	try {
		const paymentIntentResponse = await functions.httpsCallable(
			"createPayment"
		)({
			amount,
			orgId: donation?.organization?.id,
			recurring: false,
			stripeID: donation?.organization?.stripeID,
			customerId: donation?.userId,
			customerName: business?.name,
			stripeCustomerId: business?.stripeCustomerId,
			business: true
		});

		const { succeeded, paymentId } = paymentIntentResponse.data;
		if (succeeded) {
			delete donation.organization;
			delete donation.user;
			donation.corporateStatus = 1;
			donation.paymentId = paymentId;
			donation.date = new Date();
			donation.amount = amount;
			createDonationHistory(donation);
			console.log("Payment successful!!! ", paymentId);
			updateDonation(donation.id, { match: amount });
			updateOrganization(
				business,
				{ totalMatchAmount: firebase.firestore.FieldValue.increment(amount) },
				[],
				[],
				() => { }
			);
		}
	} catch (err) {
		console.log("Payment error occurred!!! ", err);
	}
};

export const oneTimeDonation = async (
	business,
	org,
	amount,
	selectedMethod,
	coverFees,
	finalFee,
	onSuccess
) => {
	// console.log('one time', amount)
	try {
		if (selectedMethod) {
			let paymentIntentResponse;
			if (selectedMethod?.type === "card") {
			paymentIntentResponse = await functions.httpsCallable(
				"createPaymentIntent"
			)({
				amount,
				calculatedFee: finalFee,
				orgId: org?.id,
				recurring: false,
				stripeID: org?.stripeID,
				stripeCustomerId: business?.stripeCustomerId,
				paymentMethodId: selectedMethod?.id,
				companyId: business?.id,
			});
			} else {
				paymentIntentResponse = await functions.httpsCallable(
					"createBankPaymentIntent"
				)({
					amount,
					calculatedFee: finalFee,
					orgId: org?.id,
					recurring: false,
					stripeID: org?.stripeID,
					stripeCustomerId: business?.stripeCustomerId,
					selectedMethodId: selectedMethod?.id,
					companyId: business?.id,
				});
			}

			const { succeeded, paymentId } = paymentIntentResponse.data;
			if (succeeded) {
				onSuccess();
				console.log("Payment successful!!! ", paymentId);
				updateOrganization(
					business,
					{
						totalOneTimeDonations: firebase.firestore.FieldValue.increment(amount)
					},
					[],
					[],
					() => { }
				);
			} else {
				window.alert(
					"There was an error processing your donation. Please try again."
				);
				onSuccess();
			}
		} else {
			const transferResponse = await functions
				.httpsCallable('createDonationFromWallet')({
					amount,
					destination: org?.stripeID,
					stripeCustomerId: business?.stripeCustomerId,
					customerId: business.id, // rayze user id
					customerName: business.name, // rayze user name
					orgId: org?.id, // nonprofit id
					orgName: org?.name, // nonprofit name
					companyId: null, // company id
					ein: org?.ein, // nonprofit ein
					coverFees,
					finalFee,
					companyId: business?.id,
				})
				.catch(error => {
					console.log('error', error);
				});

			console.log('transferResponse', transferResponse);
			if (transferResponse?.data?.error) {
				window.alert('Error', transferResponse?.data?.error);
				onSuccess();
			} else {
				updateOrganization(
					business,
					{
						totalOneTimeDonations: firebase.firestore.FieldValue.increment(amount)
					},
					[],
					[],
					() => { }
				);
				onSuccess();
			}
		}
	} catch (err) {
		console.log("Payment error occurred!!! ", err);
	}
};

export const createDonationHistory = (donation) => {
	firestore
		.collection("donations_corporate_match")
		.doc(donation.id)
		.set(donation);
};
