import React, { useEffect, useState } from "react";
import { PrimaryText } from "components/Common/PrimaryText";
import { ActivityIndicator, Dimensions, View } from "react-native-web";
import MuiButton from "components/Common/MuiButton";
import { QnAEdit } from "components/Common/QnAEdit";
import { MONTHS } from "constants/Data";
import { COLORS } from "constants/Colors";
import { useOrganization } from "context/OrganizationContext";
import {
	updateOrgPics,
	updateOrganization,
	uploadPhoto
} from "services/organizations-service";
import { dollarFormatter } from "utils/helpers";
import { useBusinessNonprofits } from "context/Business/BusinessNonprofitsContext";
import { Box, IconButton, Modal } from "@mui/material";
import { functions } from "utils/firebase";
import { useAuth } from "context/AuthContext";
import SetupStripe from "./SetupStripe";
import EmployeeMatchingSetup from "./EmployeeMatchingSetup";
import { useBusiness } from "context/Business/BusinessContext";
import { NonprofitSelectionModal } from "components/Modals/NonprofitSelectionModal";
import { PaymentMethodModal } from "components/Modals/PaymentMethodModal";
import UploadPhoto from "components/Inputs/UploadPhoto";
import EditableAddress from "components/Common/EditableAddress";
import UploadPhotoModal from "components/Modals/UploadPhotoModal";
import validate from "utils/validation";
import PaymentMethodPickerModal from "components/Modals/PaymentMethodPickerModal";
import { useBusinessDonations } from "context/Business/BusinessDonationsContext";
import {
	PaymentElement,
	useElements,
	useStripe
} from "@stripe/react-stripe-js";
import { Close } from "@mui/icons-material";
import PrimaryButton from "components/Buttons/PrimaryButton";
import SettingsTab from "components/Tabs/SettingsTab";

const { height } = Dimensions.get("window");

export function GeneralSettings() {
	const stripe = useStripe();
	const elements = useElements();
	const { currentAuth } = useAuth();
	const { selectedOrg } = useOrganization();
	const { setGetNonprofits } = useBusinessNonprofits();
	const {
		paymentMethods,
		stripeCustomer,
		getData,
		defaultPaymentMethod,
		fetchPaymentMethods
	} = useBusiness();
	const { rayzeAccount } = useBusinessDonations();

	// console.log(selectedOrg)

	const [editing, setEditing] = useState(null);
	const [showNonprofitModal, setShowNonprofitModal] = useState(false);
	const [selectedNonprofits, setSelectedNonprofits] = useState(
		selectedOrg?.supportedNonprofits || []
	);
	const [stripeCustomerId, setStripeCustomerId] = useState(
		selectedOrg?.stripeCustomerId
	);
	const [stripeSetupOpen, setStripeSetupOpen] = useState(false);
	const [matchingSetupOpen, setMatchingSetupOpen] = useState(
		!selectedOrg?.matchSettingsComplete
	);
	const [setupStep, setSetupStep] = useState(0);
	const [errors, setErrors] = useState({});

	//manage payment methods
	const [showManagePaymentMethods, setShowManagePaymentMethods] =
		useState(false);
	const [clientSecret, setClientSecret] = useState(null);
	const [activeTab, setActiveTab] = useState("Profile");

	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setStripeSetupOpen(!selectedOrg?.stripeSettingsComplete);
		setMatchingSetupOpen(!selectedOrg?.matchSettingsComplete);
	}, [stripeCustomer]);

	useEffect(() => {
		if (!stripeSetupOpen) {
			getData();
		}
	}, [stripeSetupOpen]);

	function handleSave(key, value) {
		console.log("save", key, value);

		try {
			if (key === "website") validate({ value, type: "url", name: key });
			else if (key === "ein") validate({ value, type: "ein", name: key });
			else if (key === "adminEmail")
				validate({ value, type: "email", name: key });
			else if (key === "phone") validate({ value, type: "phone", name: key });
			else if (key === "name") validate({ value, type: "name", name: key });

			if (value === "Yes" || value === "No") value = value === "Yes";

			let data = {
				[key]: value
			};

			if (key === "address") data = { ...value };

			updateOrganization(
				selectedOrg,
				data,
				key === "coverPhotos" ? [value] : [],
				[],
				onSuccessUpdate
			);
		} catch (err) {
			if (err?.name) setErrors({ [err?.name]: err.message });
			console.log(err);
		}
	}

	function onSuccessUpdate(data) {
		console.log("success");
		setEditing(null);
		setShowNonprofitModal(false);
	}

	useEffect(() => {
		if (!showNonprofitModal) return;
		setGetNonprofits(true);
	}, [showNonprofitModal]);

	async function startStripeSetup() {
		setLoading(true);
		if (selectedOrg?.stripeCustomerId) {
			return openStripeCustomerPortal();
		}
		// return console.log('startStripeSetup', currentAuth.email)
		const setupIntent = await functions.httpsCallable("createBusinessCustomer")(
			{
				customerName: selectedOrg.name,
				email: currentAuth.email
			}
		);

		const { stripeCustomerId } = setupIntent.data;
		setStripeCustomerId(stripeCustomerId);
		openStripeCustomerPortal();

		// console.log('yay', stripeCustomerId, currentAuth.email)
	}

	async function openStripeCustomerPortal() {
		const { data } = await functions.httpsCallable("createSetupIntent")({
			customerName: selectedOrg.name,
			customerId: selectedOrg.id,
			stripeCustomerId
		});

		console.log("clientSecret", data);

		const { clientSecret } = data;

		elements.update({
			mode: "setup",
			currency: "usd",
			payment_method_types: ["card", "us_bank_account"]
		});
		elements.update({ clientSecret });
		setClientSecret(clientSecret);

		setLoading(false);

		return;

		// const url = "https://billing.stripe.com/p/login/28o8wBaKwck62di144";
		const url = "https://billing.stripe.com/p/login/test_6oE4gq00WfAudwcbII";
		setLoading(false);
		window.open(url, "_blank");
	}

	const submitNewPaymentMethod = async () => {
		setLoading(true);

		const { setupIntent, error } = await stripe.confirmSetup({
			elements,

			redirect: "if_required"
		});

		elements.update({ clientSecret: undefined });

		if (error) {
			window.alert(`Error code: ${error.code}`, error.message);
			// setLoading(false);
		} else if (setupIntent) {
			setClientSecret(null);
			// setGetNewPaymentMethod(false);
			fetchPaymentMethods();
			console.log("Payment status:", setupIntent.status);
			// setLoading(false);
			if (setupIntent.status === "RequiresConfirmation") {
				console.log("yay");
			}
		}

		setLoading(false);
	};

	function updateStripeCustomerId() {
		if (!stripeCustomerId) return;
		updateOrganization(selectedOrg, { stripeCustomerId }, [], [], () => { });
	}

	const handleCancel = () => {
		setEditing(null);
		// if (errors) setErrors(null);
	};

	// Handle upload cover, profile and logo images
	const handleUpload = (image, type) => {
		setLoading(true);
		uploadPhoto({ orgId: selectedOrg?.id, imageUrl: image, type })
			.then((result) => console.log(result))
			.catch((err) => console.log(err))
			.finally((_) => {
				setLoading(false);
				setEditing(null);
			});
	};

	let secondaryPaymentMethod = defaultPaymentMethod
		? defaultPaymentMethod?.card
			? "Card " + defaultPaymentMethod.card?.last4
			: "Bank Acct " + defaultPaymentMethod.us_bank_account?.last4
		: "";

	let monthlyEmployeeGift = `$0 x 0 employees = $${selectedOrg?.monthlyEmployeeGift || 0
		}/mo`;

	return (
		<>
			<View style={{ width: "100%" }}>
				<PrimaryText
					fontSize={32}
					fontWeight={900}
				>
					General Settings
				</PrimaryText>

				<SettingsTab
					options={[
						{ label: "Profile" },
						{ label: "Payments" },
						{ label: "Rayze Wallet" },
						{ label: "Employee Matching" }
					]}
					onChange={(e) => setActiveTab(e)}
					activeTab={activeTab}
				/>

				<View
					style={{
						marginTop: 44
					}}
				/>

				{/* Profile Settings */}
				{activeTab === "Profile" && (
					<>
						<Box
							mb={25}
							mx="3%"
						>
							<PrimaryText fontSize={24}>Profile Settings</PrimaryText>

							<Box position="relative" px="2%">
								<UploadPhoto
									value={selectedOrg?.coverPhoto}
									onClick={() => setEditing("coverPhoto")}
									style={{
										
										marginTop: 12
									}}
								/>

								<PrimaryText
									fontSize={14}
									fontWeight={700}
									style={{ position: "absolute", top: "60%", left: "8%" }}
									color={COLORS.primary.gray}
								>
									Change Cover Photo<span style={{ color: "red" }}>*</span>
								</PrimaryText>
							</Box>
						</Box>

						<Box
							mb={55}
							px="3%"
						>
							<Box
								px="2%"
							>
								<QnAEdit
									required={true}
									type="text"
									question="Organization Name"
									answer={selectedOrg?.name}
									property="name"
									onEdit={(property) => setEditing(property)}
									editing={editing}
									onSave={handleSave}
									onCancel={() => setEditing(null)}
									errorMessage={errors?.name}
								/>

								<EditableAddress
									required={true}
									title="Mailing Address (should match IRS documents)"
									selectedAddress={selectedOrg?.address}
									property="address"
									onEdit={(property) => setEditing(property)}
									editing={editing}
									onSave={handleSave}
									onCancel={handleCancel}
								/>

								<QnAEdit
									required={true}
									type="text"
									question="Admin Email"
									answer={selectedOrg?.adminEmail}
									property="adminEmail"
									onEdit={(property) => setEditing(property)}
									editing={editing}
									onSave={handleSave}
									onCancel={() => setEditing(null)}
									errorMessage={errors?.adminEmail}
								/>

								<QnAEdit
									required={true}
									type="text"
									question="Phone Number"
									answer={selectedOrg?.phone}
									property="phone"
									onEdit={(property) => setEditing(property)}
									editing={editing}
									onSave={handleSave}
									onCancel={() => setEditing(null)}
									errorMessage={errors?.phone}
								/>
							</Box>

							<Box
								px="2%"
							>
								<QnAEdit
									required={true}
									type="text"
									question="Employer Identification Number (EIN)"
									answer={selectedOrg?.ein}
									property="ein"
									onEdit={(property) => setEditing(property)}
									editing={editing}
									onSave={handleSave}
									onCancel={() => setEditing(null)}
									errorMessage={errors?.ein}
								/>

								<QnAEdit
									required={true}
									type="text"
									question="About (500 character max)"
									answer={selectedOrg?.about}
									property="about"
									onEdit={(property) => setEditing(property)}
									editing={editing}
									onSave={handleSave}
									onCancel={() => setEditing(null)}
									maxWidth={550}
								/>

								<QnAEdit
									required={true}
									type="text"
									question="Website URL"
									answer={selectedOrg?.website}
									property="website"
									onEdit={(property) => setEditing(property)}
									editing={editing}
									onSave={handleSave}
									onCancel={() => setEditing(null)}
									errorMessage={errors?.website}
								/>
							</Box>
						</Box>
					</>
				)}

				{activeTab === "Payments" && (
					<View style={{ paddingHorizontal: "3%", height: "100%" }}>
						<View style={{ flexGrow: 1, alignItems: "flex-start" }}>
							<PrimaryText fontSize={24}>Admin Settings</PrimaryText>
							<Box
								px="2%"
							>
								<MuiButton
									color="primary"
									sx={{ mb: 37, width: 300, mt: 26 }}
									variant="contained"
									title={
										selectedOrg?.stripeCustomerId && paymentMethods?.length > 0
											? "Manage Payment Methods"
											: "Setup Payment Methods"
									}
									onClick={() =>
										selectedOrg?.stripeCustomerId && paymentMethods?.length > 0
											? setShowManagePaymentMethods(true)
											: startStripeSetup()
									}
								/>

								<QnAEdit
									type="radio"
									caption="This enables your employees to donate to organizations anonymously."
									question="Allow Anonymous Employee Donations?"
									property={"allowAnonymousDonations"}
									answer={selectedOrg?.allowAnonymousDonations ? "Yes" : "No"}
									onEdit={(property) => setEditing(property)}
									editing={editing}
									onSave={handleSave}
									onCancel={() => setEditing(null)}
								/>

								<QnAEdit
									type="radio"
									caption="This automatically approves all employee donations to approved nonprofits."
									question="Automatically Approve Employee Donations?"
									property={"autoApproveDonations"}
									answer={selectedOrg?.autoApproveDonations ? "Yes" : "No"}
									onEdit={(property) => setEditing(property)}
									editing={editing}
									onSave={handleSave}
									onCancel={() => setEditing(null)}
									disabled={true}
									disabledText="This feature is not yet available."
								/>

								<QnAEdit
									placeholder="Month"
									type="picker"
									caption="Determine when you fiscal year begins for tax implications"
									question="Beginning of Fiscal Year:"
									property={"fiscalYear"}
									value={
										selectedOrg?.fiscalYear ? selectedOrg?.fiscalYear : "January"
									}
									answer={
										selectedOrg?.fiscalYear ? selectedOrg?.fiscalYear : "Month"
									}
									options={MONTHS}
									onEdit={(property) => setEditing(property)}
									editing={editing}
									onSave={handleSave}
									onCancel={() => setEditing(null)}
								/>
							</Box>

							{/* {stripeSetupOpen && ( */}
							<SetupStripe
								stripeSetupOpen={stripeSetupOpen}
								onSetupStripe={startStripeSetup}
								onClose={
									selectedOrg?.stripeCustomerId
										? () => {
											updateOrganization(
												selectedOrg,
												{ stripeSettingsComplete: true },
												[],
												[],
												() => {
													setStripeSetupOpen(false);
												}
											);
										}
										: null
								}
								updateStripeCustomerId={updateStripeCustomerId}
								currentUser={currentAuth}
								setupStep={setupStep}
								setSetupStep={setSetupStep}
								selectedOrg={selectedOrg}
							/>
							{/* )} */}
						</View>
					</View>
				)}

				{activeTab === "Rayze Wallet" && (
					<View style={{ paddingHorizontal: "3%", height: "100%" }}>
						<View style={{ flexGrow: 1, alignItems: "flex-start" }}>
							<PrimaryText
								fontSize={24}
								style={{ marginTop: 8, marginBottom: 20 }}
							>
								Rayze Wallet Account Settings
							</PrimaryText>

							<Box
								px="2%"
							>
								<QnAEdit
									type="text"
									placeholder="Recurring Deposit"
									caption="Set the recurring deposit to your Rayze Account each month."
									question="Rayze Account Recurring Monthly Deposit:"
									property="recurringMonthlyDeposit"
									answer={`$${selectedOrg?.recurringMonthlyDeposit || 0}/mo`}
									value={selectedOrg?.recurringMonthlyDeposit || 0}
									number
									onEdit={(property) => setEditing(property)}
									editing={editing}
									onSave={handleSave}
									onCancel={() => setEditing(null)}
									disabledText="This feature is not yet available."
								// disabled={true}
								/>

								<QnAEdit
									type="text"
									placeholder="Employee Gift"
									caption="Set the recurring employee gift from your Rayze Account each month."
									question="Rayze Account Recurring Monthly Employee Gift:"
									property="monthlyEmployeeGift"
									answer={monthlyEmployeeGift}
									value={selectedOrg?.monthlyEmployeeGift || 0}
									number
									onEdit={(property) => setEditing(property)}
									editing={editing}
									onSave={handleSave}
									onCancel={() => setEditing(null)}
									disabledText="This feature is not yet available."
								// disabled={true}
								/>

								<QnAEdit
									type="text"
									placeholder="Secondary Payment Method"
									caption="It will be used when primary method doesn't work."
									question="Secondary Payment Method:"
									property="secondaryPaymentMethod"
									answer={
										<PrimaryText>
											Stripe Payment Method{" "}
											<PrimaryText fontWeight={400}>
												| {secondaryPaymentMethod}
											</PrimaryText>
										</PrimaryText>
									}
									value={selectedOrg?.backupPaymentMethod || 0}
									number
									onEdit={(property) => setEditing(property)}
									// editing={editing}
									onSave={handleSave}
									onCancel={() => setEditing(null)}
									// disabled={true}
									disabledText="This feature is not yet available."
								/>
							</Box>
						</View>
					</View>
				)}

				{/** MATCH SETTINGS */}
				{activeTab === "Employee Matching" && (
					<View style={{ paddingHorizontal: "3%", flex: 1 }}>
						<Box
							position="relative"
							display="flex"
							flexDirection="column"
							alignItems="flex-start"
						>
							<PrimaryText
								fontSize={24}
							// style={{
							// 	marginBottom: paymentMethods?.length === 0 ? 26 : 0
							// }}
							>
								Employee Matching Settings
							</PrimaryText>
							{paymentMethods?.length === 0 ? (
								<Box
									mb={26}
								>
									<PrimaryText
										fontSize={14}
										fontWeight={300}
									>
										To enable match settings please setup a payment method.
									</PrimaryText>
								</Box>
							) : (
								<Box
									px="2%"
									pb={26}
									flexDirection={"column"}
									display="flex"

								>
									<MuiButton
										title={
											selectedOrg?.activeMatching
												? "Disable All Matching"
												: "Re-activate Matching"
										}
										variant="outlined"
										color={selectedOrg?.activeMatching ? "error" : "success"}
										sx={{ mb: 4 }}
										onPress={() =>
											handleSave("activeMatching", !selectedOrg?.activeMatching)
										}
									/>

									<PrimaryText
										fontSize={14}
										fontWeight={300}
									>
										{selectedOrg?.activeMatching
											? "This will stop all corporate matching until it is re-activated."
											: "This will re-activate all matching for your company."}
									</PrimaryText>
								</Box>
							)}

							<Box
								px="2%"
							>


								<QnAEdit
									type="radio"
									caption="This enables you to choose whether employees can donate to any Nonprofit that is on the platform or just the ones you specify."
									question="Allow Donations To All Nonprofits?"
									property={"allowDonationsToAllNonprofits"}
									answer={
										selectedOrg?.allowDonationsToAllNonprofits ? "Yes" : "No"
									}
									onEdit={(property) => setEditing(property)}
									editing={editing}
									onSave={handleSave}
									onCancel={() => setEditing(null)}
									disabled={paymentMethods?.length === 0}
								/>

								{!selectedOrg?.allowDonationsToAllNonprofits && (
									<MuiButton
										color="primary"
										sx={{ mb: 37 }}
										variant="contained"
										title="View/Edit Supported Nonprofits"
										onClick={() => setShowNonprofitModal(true)}
									/>
								)}

								<QnAEdit
									type="radio"
									caption="Restricts donation matching to only company campaigns."
									question="Allow matching outside of campaigns?"
									property={"matchingOutsideCampaigns"}
									answer={selectedOrg?.matchingOutsideCampaigns ? "Yes" : "No"}
									onEdit={(property) => setEditing(property)}
									editing={editing}
									onSave={handleSave}
									onCancel={() => setEditing(null)}
									disabled={true}
									disabledText="This feature is not yet available."
								/>

								<QnAEdit
									type="text"
									placeholder="Match Percentage"
									caption="Determine the percentage match of each employee donation your company will make."
									question="Match Percentage:"
									property={"matchPercentage"}
									answer={selectedOrg?.matchPercentage + "%"}
									value={selectedOrg?.matchPercentage}
									number
									onEdit={(property) => setEditing(property)}
									editing={editing}
									onSave={handleSave}
									onCancel={() => setEditing(null)}
									disabled={paymentMethods?.length === 0}
								/>

								<QnAEdit
									type="text"
									placeholder="Max Match"
									caption="Determine the maximum match attributed to individual employees each year."
									question="Max match per employee for fiscal year:"
									property={"maxEmployeeMatchPerYear"}
									answer={
										selectedOrg?.maxEmployeeMatchPerYear
											? dollarFormatter
												.format(selectedOrg?.maxEmployeeMatchPerYear)
												.slice(0, -3)
											: "No Limit"
									}
									value={
										selectedOrg?.maxEmployeeMatchPerYear
											? selectedOrg?.maxEmployeeMatchPerYear
											: ""
									}
									number
									onEdit={(property) => setEditing(property)}
									editing={editing}
									onSave={handleSave}
									onCancel={() => setEditing(null)}
									disabled={paymentMethods?.length === 0}
								/>

								<QnAEdit
									type="text"
									placeholder="Hourly Volunteer Compensation"
									caption="Determine the compensation amount per volunteer hour for employees (IE: $15.00/hr volunteered)"
									question="Set hourly volunteer compensation match:"
									property={"hourlyVolunteerCompensation"}
									answer={
										(selectedOrg?.hourlyVolunteerCompensation
											? dollarFormatter
												.format(selectedOrg?.hourlyVolunteerCompensation)
												.slice(0, -3)
											: "$15") + "/hr"
									}
									value={
										selectedOrg?.hourlyVolunteerCompensation
											? selectedOrg?.hourlyVolunteerCompensation
											: ""
									}
									number
									onEdit={(property) => setEditing(property)}
									editing={editing}
									onSave={handleSave}
									onCancel={() => setEditing(null)}
									disabled={true}
									disabledText="Coming soon!"
								/>

								<QnAEdit
									type="text"
									placeholder="Total contribution"
									caption="Determine the total yearly match allotment your company will make."
									question="Total company max allotment for fiscal year:"
									property={"maxCompanyMatchPerYear"}
									answer={
										selectedOrg?.maxCompanyMatchPerYear
											? dollarFormatter
												.format(selectedOrg?.maxCompanyMatchPerYear)
												.slice(0, -3)
											: "No Limit"
									}
									value={
										selectedOrg?.maxCompanyMatchPerYear
											? selectedOrg?.maxCompanyMatchPerYear
											: ""
									}
									number
									onEdit={(property) => setEditing(property)}
									editing={editing}
									onSave={handleSave}
									onCancel={() => setEditing(null)}
									disabled={paymentMethods?.length === 0}
								/>

								{matchingSetupOpen && (
									<EmployeeMatchingSetup
										onClose={
											selectedOrg?.matchPercentage > 0
												? () => {
													updateOrganization(
														selectedOrg,
														{ matchSettingsComplete: true },
														[],
														[],
														() => {
															setMatchingSetupOpen(false);
														}
													);
												}
												: null
										}
										selectedOrg={selectedOrg}
										onEditSupportedNonprofits={() => setShowNonprofitModal(true)}
									/>
								)}

								{/* <QnAEdit
								type="text"
								placeholder="Default Matching Payment Method"
								caption="It will be used when primary method doesn't work."
								question="Default Matching Payment Method:"
								property="defaultMatchingPaymentMethod"
								answer={
									<PrimaryText>
										Rayze Account{" "}
										<PrimaryText fontWeight={400}>
											| Balance $10,298
										</PrimaryText>
									</PrimaryText>
								}
								value={selectedOrg?.defaultMatchingPaymentMethod || 0}
								number
								onEdit={(property) => setEditing(property)}
								disabled={true}
								disabledText="This feature is not yet available."
							/> */}
								{/* 
							<QnAEdit
								type="text"
								placeholder="Backup Matching Payment Method"
								caption="It will be used when primary method doesn't work."
								question="Backup Matching Payment Method:"
								property="backupMatchingPaymentMethod"
								answer={
									<PrimaryText>
										Stripe Payment Method{" "}
										<PrimaryText fontWeight={400}>| Bank Acct 1111</PrimaryText>
									</PrimaryText>
								}
								value={selectedOrg?.backupMatchingPaymentMethod || 0}
								number
								onEdit={(property) => setEditing(property)}
								disabled={true}
								disabledText="This feature is not yet available."
							/> */}



							</Box>
						</Box>
					</View>
				)}


			</View>

			{showNonprofitModal && (
				<NonprofitSelectionModal
					supportedNonprofits={selectedNonprofits}
					showNonprofitModal={showNonprofitModal}
					setShowNonprofitModal={setShowNonprofitModal}
					onUpdateSupportedNonprofits={(supportedNonprofits) => {
						updateOrganization(
							selectedOrg,
							{ supportedNonprofits },
							[],
							[],
							() => setShowNonprofitModal(false)
						);
					}}
				/>
			)}

			{showManagePaymentMethods && (
				<PaymentMethodModal
					isVisible={showManagePaymentMethods}
					setIsVisible={setShowManagePaymentMethods}
					onAdd={startStripeSetup}
				/>
			)}

			{editing === "coverPhoto" && (
				<UploadPhotoModal
					onCancel={handleCancel}
					isOpen={true}
					title="Cover Photo"
					onSave={(image) => handleUpload(image, "cover")}
					loading={loading}
				/>
			)}

			{/* Select payment method modal */}
			<PaymentMethodPickerModal
				isVisible={editing === "secondaryPaymentMethod"}
				onClose={() => setEditing(false)}
				modal={true}
				modalTitle={"Secondary Payment Method"}
				onChange={(e) => console.log(e)}
				paymentMethods={paymentMethods}
				rayzeAccount={rayzeAccount}
				defaultPaymentMethod={defaultPaymentMethod}
				onAdd={startStripeSetup}
				selectedOrg={selectedOrg}
				fetchPaymentMethods={getData}
			/>

			{clientSecret && (
				<Modal
					open={true}
					onClose={() => setClientSecret(null)}
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center"
					}}
				>
					<Box
						// width={{ base: 438 }}
						// height={{ base: 600 }}
						pt={30}
						pb={30}
						bgcolor={COLORS.primary.neutral_100}
						display="flex"
						flexDirection="column"
						justifyContent="center"
						borderRadius={3}
						position="relative"
						px={120}
						py={60}
						boxSizing="border-box"
					>
						<IconButton
							sx={{ position: "absolute", top: 16, right: 20 }}
							onClick={() => setClientSecret(null)}
						>
							<Close fill={COLORS.primary.gray} />
						</IconButton>
						<PaymentElement />
						<PrimaryButton
							title="Confirm"
							style={{
								borderRadius: 20,
								paddingHorizontal: 24,
								marginTop: 24,
								marginBottom: 0
							}}
							onPress={submitNewPaymentMethod}
						// disabled={defaultPaymentMethod?.id === selectedPaymentMethod?.id}
						/>
					</Box>
				</Modal>
			)}

			{loading && (
				<Box
					width="100%"
					height="100%"
					bgcolor="rgba(255, 255, 255, 0.5)"
					position="absolute"
					top={0}
					left={0}
					display={"flex"}
					justifyContent="center"
				// alignItems="center"
				>
					<ActivityIndicator
						animating={true}
						size={"large"}
						color={COLORS.primary.blue}
						style={{ marginBottom: "30%" }}
					/>
				</Box>
			)}
		</>
	);
}
